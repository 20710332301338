import { selectUserConnected } from '@/store/reducers/authentification/authentification.selector';
import { connect, ConnectedProps } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import { protectedRoutes } from './protected.routes';
import { publicRoutes } from './public.routes';

type PropsFromRedux = ConnectedProps<typeof connector>;

type AppRoutesProps = PropsFromRedux & {
	userToken?: string;
};

const AppRoutes: React.FC<AppRoutesProps> = ({ userConnected }) => {
	const routes = userConnected ? protectedRoutes : publicRoutes;
	const element = useRoutes([...routes]);
	return <>{element}</>;
};

const mapStateToProps = createStructuredSelector({
	userConnected: selectUserConnected,
});

const connector = connect(mapStateToProps);
export default connector(AppRoutes);
