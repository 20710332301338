import React, { useEffect } from 'react';
import './Navbar.styles.scss';
import logoCie from '@/assets/images/logo_cie.png';
import robotIcon from '@/assets/svgs/robot.svg';
import { Icon } from '@iconify/react';
import Popover from '@mui/material/Popover';
import {
	Button,
	ListItemIcon,
	ListItemText,
	MenuItem,
	MenuList,
	Paper,
	Typography,
} from '@mui/material';
import { Logout } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { routePaths } from '@/routes/routesPath';
import { store } from '@/store';
import { logoutUser } from '@/store/reducers/authentification/authentification.reducer';

type TNavBarProps = {
	setIsCamapagnePage: React.Dispatch<React.SetStateAction<boolean>>;
	userConnected?: any;
};

const NavBar: React.FC<TNavBarProps> = ({
	setIsCamapagnePage,
	userConnected,
}) => {
	const dispatch = store.dispatch;
	const navigate = useNavigate();
	const libelleLecteur = 'Lecteur';
	const logout = async () => {
		sessionStorage.removeItem('token');
		dispatch(logoutUser());
	};

	const goTo = (route: string = '/my-space') => {
		navigate(`${route}`);
	};

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [isUsersPage, setIsUsersPage] = React.useState(false);

	useEffect(() => {
		setIsCamapagnePage(!isUsersPage);
	}, [isUsersPage]);

	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	return (
		<div className="nav-container">
			<div className="nav-part-left">
				<div className="nav-logo">
					<img
						src={logoCie}
						alt="Logo Ecolite"
						onClick={() => {
							goTo(routePaths.dashboard);
							setIsUsersPage(false);
						}}
					/>
				</div>
			</div>

			<div className="nav-part-right">
				{userConnected &&
					userConnected?.profil &&
					userConnected?.profil.libelle != libelleLecteur && (
						<div className="navbar-menu-container">
							<Button
								aria-describedby={id}
								variant="contained"
								onClick={() => {
									goTo(routePaths.dashboard);
									setIsUsersPage(false);
								}}
								className={
									'menu-item ' +
									(!isUsersPage ? 'active' : '')
								}
							>
								<Icon
									icon="ps:organisation"
									className="navbar-icon"
								/>
								<span> Compagnes </span>
							</Button>
							<Button
								aria-describedby={id}
								variant="contained"
								onClick={() => {
									goTo(routePaths.userManage);
									setIsUsersPage(true);
								}}
								className={
									'menu-item ' + (isUsersPage ? 'active' : '')
								}
							>
								<Icon
									icon="ph:users-four"
									className="navbar-icon"
								/>
								<span> Utilisateurs </span>
							</Button>
						</div>
					)}

				<Button
					aria-describedby={id}
					variant="contained"
					onClick={handleClick}
					className="profil"
				>
					<div className="photo-id">
						<img src={robotIcon} alt="" />
					</div>
					<div className="names">
						<span>
							{userConnected.nom} {userConnected.prenoms}
						</span>
						<small>{userConnected.profil.libelle}</small>
					</div>
					<Icon icon="tabler:chevron-down" className="icon-chevron" />
				</Button>
				<Popover
					id={id}
					open={open}
					anchorEl={anchorEl}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
				>
					<Paper sx={{ width: 320, maxWidth: '100%' }}>
						<MenuList>
							<MenuItem>
								<Typography
									variant="body2"
									color="text.secondary"
								>
									CIE - ecolite
								</Typography>
							</MenuItem>

							{/* {
                userConnected && userConnected?.profil && userConnected?.profil.libelle != libelleLecteur && 
                <>
                   <MenuItem onClick={() => goTo(routePaths.userSpace.path)}>
                      <ListItemIcon>
                        <Settings fontSize="small" />
                      </ListItemIcon>
                      <ListItemText> Tableau de bord </ListItemText>
                    </MenuItem>
                    <Divider />
                </>
              } */}

							<MenuItem
								onClick={() => {
									goTo(routePaths.login);
								}}
							>
								<ListItemIcon>
									<Logout fontSize="small" />
								</ListItemIcon>
								<ListItemText onClick={logout}>
									{' '}
									Déconnexion{' '}
								</ListItemText>
							</MenuItem>
						</MenuList>
					</Paper>
				</Popover>
			</div>
		</div>
	);
};

export default NavBar;
