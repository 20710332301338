import { userAuthentificate } from '@/pages/login/api/auth.api';
import { TSignIn } from '@/pages/login/types';
import { setAuthToken } from '@/utils/setAuthToken';
import { ToastGeneric } from '@/utils/toast';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { decodeToken } from 'react-jwt';

interface IUserState {
	isLoading: boolean;
	userConnected: any;
	token: string;
}

const initialState: IUserState = {
	isLoading: false,
	userConnected: null,
	token: '',
};

export const loginUser = createAsyncThunk(
	'user/loginUser',
	async (user: TSignIn, thunkOptions) => {
		const response = await userAuthentificate(user);
		if (response.error) {
			return thunkOptions.rejectWithValue(response?.message);
		}
		if (response.data.token) {
			sessionStorage.setItem('token', response.data.token);
			setAuthToken(response.data?.token);
		}

		// let userInfos = this.jwtHelper.decodeToken(data.data.access_token);

		let userInfos: any;

		if (response.data?.token) {
			userInfos = decodeToken(response.data?.token) as any;
		} else {
			userInfos = response.data;
		}
		return userInfos?.user ? userInfos?.user : userInfos;
	}
);

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		logoutUser: state => initialState,
	},
	extraReducers: builder => {
		builder.addCase(loginUser.pending, state => {
			state.isLoading = true;
		});
		builder.addCase(loginUser.fulfilled, (state, { payload }) => {
			const user = payload;

			if (user?.is_actif) {
				ToastGeneric(
					`Bienvenue ${user?.nom} ${user?.prenoms}  , vous êtes connecté`,
					'success',
					5000
				).fire();
			}

			state.isLoading = false;
			// state.token = payload?.token;
			state.userConnected = user;
		});
		builder.addCase(loginUser.rejected, (state, { payload }) => {
			state.isLoading = false;
			payload && ToastGeneric(`${payload}`, 'error', 9000).fire();
		});
	},
});

export const { logoutUser } = userSlice.actions;

export default userSlice.reducer;
