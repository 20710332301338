import { Helmet } from 'react-helmet-async';
import { AppProvider } from '@/provider';
import AppRoutes from '@/routes';
import './App.scss';

const App: React.FC = () => {
	return (
		<>
			<AppProvider>
				<Helmet>
					<title> Ecolite - CIE </title>
					<link
						rel="preconnect"
						href="https://fonts.googleapis.com"
					/>
					{/*
            // @ts-ignore */}
					<link rel="preconnect" href="https://fonts.gstatic.com" />
					<link
						href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap"
						rel="stylesheet"
					/>
					<link
						rel="stylesheet"
						href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
					/>
				</Helmet>
				<AppRoutes />
			</AppProvider>
		</>
	);
};

export default App;
